import { actions } from "./action";
import mutations from "./mutations";
import { getters } from "./getters";

import { STATE_STATUSES } from "@/helpers/constants";
import { reactive } from "@vue/reactivity";

const state = reactive({
  allPlans: null,
  status: STATE_STATUSES.INIT,
  statusPlanPrice: STATE_STATUSES.INIT,
  fullDetailPlan: null,
  fullPlanDetailBenefits: null,
  userOrder: null,
  createOrder: [],
  holdUpdatePlanPrice: null,
  cart: [],
  statuscartAddLoading: STATE_STATUSES.INIT,
  statuscartListingloaing: STATE_STATUSES.INIT,
  guest_user: "",
  cartItemNumber: null,
  emergencyMedEvac: null,
  selectedAddOns: [],
  selectedAddOnsIDs: [],
  specificUpgradesCostOnAPICallType: null,
  selected_add_on_cancel_for_any_reason: null,
  total_amount: 0,
  orderSummary: null,
  trip_side_assistance: null,
  blue_ribbon_bag: null,
  //for new checkout
  makeOrderInfo: {},
  comparePlans: [],
  loginUserData: null,
});

const plans = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default plans;
